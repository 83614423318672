import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b2.jpg"  alt="" />
     </td>
     </tr>
      
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>20/8/1959
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Fuoni </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 777 432 385</td>
     </tr> */}
     

     

   </table>
   </div>
      
    <h1> Dr. Ahmed Mohammed Khatib</h1>
    <hr/>
    
     <h4><strong>Education</strong></h4>
        <ul>
            <li>Masters in Public Health (Delivery of Primary Health Care Services), The University of New South Wales, Sydney, Australia - May 2006 to July, 2007</li>
            <li>Doctor of Medicine, Kharkov State Medical University, Kharkov, Ukraine – September 1995 to June 2001</li>
            <li>Post-graduate Intern, Muhimbili Medical Centre, Dar es Salaam, Tanzania - May 2002 to June 2003 </li>
            <li>Certificate of Advance Secondary Education, Lumumba High School, Zanzibar 1990 – 1992</li>
            <li>Certificate of Secondary Education – Muslim College Zanzibar, 1986-1989 </li>
        </ul>

        <h4><strong>Employment</strong></h4>
           <ul>
              <li>October 2016 to date: Executive Director of the Zanzibar AIDS Commission. I am responsible for leading coordination of multi-sectoral HIV/AIDS response on Zanzibar, overseeing HIV/AIDS Policy and coordinating its implementation, supporting the development and operationalization of National Multisectoral HIV/AIDS Strategic Plan and fostering its execution, leading coordination in implementation of National HIV/AIDS Strategic Plan, ensuring community have proper knowledge on HIV/AIDS, sharing HIV/AIDS information /data with community and other stakeholders, mapping and mobilizing resources to support Government efforts in fighting against HIV/AIDS.</li>
              <li>January, 2014 to date: Member of WHO’s Strategic and Technical Advisory Committee on Viral Hepatitis (STAC-HEP). On 8th January 2014 I was appointed and reappointed in January 2017 by the Director - General of WHO (Dr Margaret Chan) to serve as a member of the STAC for viral hepatitis. Core functions of STAC members are to provide the Director – General with an independent evaluation of the strategic and technical aspect of the viral hepatitis area of work, to review from a strategic and technical view point, WHO collaboration with, and support to, national efforts to respond to viral hepatitis epidemics, to review and make recommendations on the establishment of committees, working groups, and other means through which scientific and technical matters are addressed.</li>
              <li>February, 2011 to 2016: Program Manager, Ministry of Health, Zanzibar Integrated HIV/AIDS, TB & Leprosy Control Program, Zanzibar, Tanzania:  I was responsible for designing interventions for both programs HIV and TB/Leprosy programs, supporting development and implementation of Health Sector HIV Strategic Plan and Strategic Plan for TB & Leprosy, mapping and mobilizing funds, advising the Ministry of Health on HIV/AIDS, TB and Leprosy related issues, submission of financial and technical program reports to the Ministry of Health, partners and other stakeholders, conducting operational researches and other form of studies to inform policy and planning, undertake program monitoring and evaluation.</li>
              <li>March, 2005 to January, 2011: Care and Treatment Coordinator, Ministry of Health, Zanzibar AIDS Control Program, Zanzibar, Tanzania:  Responsible to coordinate care and treatment services for people living with HIV & AIDS, including development of HIV/AIDS Treatment guidelines, establish ARV clinics (care and treatment clinics), conducting mentorship and supportive supervisions to all care and treatment clinics, scaling up ARV services to peripheral facilities, conducting operational researches related to care and treatment services, designing care and treatment services for key populations (evening clinics, methadone clinic for people who inject drugs)</li>
              <li>March, 2008 to June, 2012: Principle Investigator for the Oral Cholera Vaccine Project (Dukoral) in Zanzibar, funded by Bill and Melinda Gate Foundation through World Health Organization and International Vaccine Institute. With local and International collaborators, I coordinated mass cholera vaccination campaign, coordinated training and supervision of vaccination team, engaged local government authority and community leaders tehri support and broader sensitization of target population, oversee implementation of operational and other form of researches (qualitative and quantitative) after vaccination.</li>
              <li>July2003 to December,2005: Doctor In charge of Female Medical Ward at Mnazi Mmoja Hospital, incharge of STI/HIV Clinic which later on became the first care and treatment clinic for People Living with HIV in Zanzibar. I was responsible for daily ward rounds, conduct morning meetings with internal medicine staff, organising continuous medical education for ward staff and running the STI/HIV clinic.</li>
           </ul>

        <h4><strong>Trainings</strong></h4>
          <ul>
              <li>Ethical Issues Impacting HIV/AIDS Research and Care, 17-18th August, 2016 University of Pennsylvenia in collaboration with the Muhimbili University of Health and Allied Sciences. Conducted in Zanzibar</li>
              <li>Survival Analysis in Public Health Research, 25th – 29th May, 2015. Harvard School of Public Health in collaboration with Africa Academy for Public Health. Conducted in Dar es salaam, Tanzania</li>
              <li>Global Intensive Professional Program in HIV, 25th August – 18th November, 2014. The University of Sydney, Australia</li>
              <li>Manuscript Writing, 15th – 19th October, 2012. Center for Disease Control and Prevention (CDC) in Collaboration with The University of Carlifonia, San Francisco (UCSF), conducted in Morogoro, Tanzania</li>
              <li>Vaccinology course, 2nd – 7th May, 2011. International Vaccine Institute, Seoul, South Korea</li>
              <li>HIV and Most at Risk Population Training, 19th to 30th June, 2009. Muhimbili National Hospital, Dar es Salaam, Tanzania</li>
              <li>Training on Grant Administration for Grantees -  Organized by CDC Tanzania February 28 – March 4, 2011, Dar es Salaam, Tanzania</li>
              <li>Pre Survey Training Workshop for Ethics Committees, 26th – 28th January, 2009</li>
              <li>Human Subject Protection Course and Standard Operating Procedure Training, 4th – 8th February 2008, Pan African Bioethics Network, Zanzibar</li>
          </ul>

          <h4><strong>Publications</strong></h4>
              <ul>
                  <li>HIV and Hepatitis B and C co-infection among People who inject Drugs in Zanzibar. Ahmed      
                      Khatib, Eva Matiko, Farhat Khalid, Susie Welty, Ameir Ali, Asha Othman, Shaaban Haji, 
                      Mohamed Dahoma and George Rutherford. BMC Public Health 2017</li>
                  <li>Reproducibility of Respondent-Driven Sampling (RDS) in Repeat Surveys of Men Who have Sex with Men, Unguja, Zanzibar. Ahmed Khatib, Shaaban haji, Maryam Khamis, Christen said, Farhat  Khalid, Mohamed Dahoma, Ameir Ali, Asha Othman, Susie Welty & Willi Mc Farland. AIDS & Behaviour, 12/2016</li>
                  <li>HIV Prevalence and Risk Behaviors Among People Who Inject drugs in Two Serial Cross-Sectional Respondent-Driven Sampling Surveys, Zanzibar 2007 and 2012. Eva Matiko, Ahmed Khatib, Farhat Khalid, Susie Welty, Christen said, Ameir Ali, Mohammed Dahoma. AIDS & Behaviour 02/2015</li>
                  <li>Estimating the Number of People Who Inject Drugs, Female Sex Workers, and Men Who Have Sex with Men, Unguja Island, Zanzibar: Results and Synthesis of Multiple Methods. Farhat J. Khalid, Fatma M. Hamad, Asha A. Othman, Ahmed M. Khatib, Sophia Mohammed, Ameir Kha. Ali, Mohammed J. U. Dahoma.  AIDS and Behaviour 05/2013</li>
                  <li>Molecular Characterization of High Cholera Toxin-Producing El Tor Variant Vibrio Cholera in Zanzibar, East Africa. A. Naha, G. Chowdhury, J. Ghosh-Banerjee, M Senoh, B. Ley, K. Thriemer, J. Deen, L V Seidlein, S M Ali, A. Khatib, A K Mukhopadhyay. Journal of Clinical Microbilogy 01/2013.</li>
                  <li>Reviewing progress: 7 year trends in characteristics of adults and children enrolled at HIV care and treatment clinics in the United Republic of Tanzania. Harriet Nuwagaba-Biribonwoha, Bonita Kilama, Gretchen Antelman, Ahmed Khatib, Annette Almeida, Gongo Ramadhan, Redempta Mbatia, Elaine J Abrams. BMC Public Health10/2013</li>
                  <li>Improving Community Coverage of Oral Cholera Mass Vaccination Campaigns: Lessons Learned in Zanzibar. Christian Schaetti, Said M. Ali, Claire-Lise Chaignat, Ahmed M. Khatib, Raymond Hutubessy, Michel G. Weiss. Plos One, 07/2012</li>
                  <li>Effectiveness of an oral cholera vaccine in Zanzibar: findings from a mass vaccination campaign in Zanzibar. Ahmed M. Khatib, Mohammad Ali, Lorenz von seidlein, Deok ryun kim, Ramadhan Hashim, Rita Reybun,Godwin Enwere, Raymond Hutubessy, Marie-Paule Kieny, Said Mohammed Ali, Abdul A. Saleh, Asish K. Mukhopadhyay, John Clemens, Mohamed S. Jiddawi, Jacqueline Deen. The Lancet Infectious Disease 09/2012</li>
                  <li>Costs of illness due to cholera, costs of immunization, and cost effectiveness of an oral cholera mass vaccination campain in Zanzibar. Christian Schaetti, Mitchell G. Weiss, said M. Ali, Claire-Lise Chaignat, Ahmed M. Khatib, Rita Reybun, Raymond hutubessy. Plos Neglected Tropical Disease 10/2012. </li>
                  <li>Safety of the recombinant cholera toxin B subunit, killed whole-cell (rBC-WC) oral cholera vaccine in pregnancy. Ramadhan Hashim, Ahmed M. Khatib, Godwin Enwere, Jin Kyung Park, Rita Reybun, Mohammed Ali, Na Yoon chang, Deok Ryun Kim, David sack, Marie-Paule Kieny, , Stephen Obaro, Said M. Ali, attiye J Shaame, Abdul A saleh, Lorenz von seidlein, mohammed S. Jiddawi. Plos Neglected Tropical Disease 07/2012.</li>
                  <li>Evaluation of a Rapid Dipstick (Chrystal VC) for the Diagnosis of Cholera in Zanzibar and a Comparison with Previous Studies. Benedict Ley, Ahmed M. Khatib, Kamala Thriemer, Lorenz von seidlein, Jacqueline Deen,  Asish Mukhopadhyay, Rita reybun, Na-Yoon chang, Ramadhan Hashim, Thomas Wierzba, David Sack, Mohammed Jiddawi, Said M. Ali. PLoS One 05/2012</li>
                  <li>Oral cholera vaccine use in Zanzibar: socioeconomic and behavioural features affecting demand and acceptance. Christian Schaetti, Raymond Hutubessy, Said M Ali, Al Pach, Mitchell G Weiss, Claire-Lise Chaignat, Ahmed M Khatib. BMC Public Health, 04/2009</li>
                  <li>HIV and related risk behaviours among men who have sex with men in Zanzibar, Tanzania: results of behavioural surveillance survey. Mohammed Dahoma, Lisa G. Johnston, Abigail Holman, Leigh Ann Miller, Mahmoud Mussa, Asha Othman, Ahmed Khatib, Ramadhan Issa, Carl Kandall, Andrea A. Kim. AIDS and Behaviour 12/2009</li>
              </ul>
    </div>  
    </div>
  )
}
import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, Container } from '@material-ui/core';

const LoginPage = () => {
  
  return (
  
 <Container component="main" maxWidth="xs"> 
        <div className="card border-info" style={{"margin-top": "200px"}}>
            <div className="card-header text-center bg-info" style={{ height: "55px","fontSize":"24px","color":"white"}}>LOG IN</div>
            <div className="card-body">
                <form>
                    <input
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Your User name"
                        name="user_name"
                        style={{ height: "55px"}}
                   />

                    <input
                        type="text"
                        className="form-control bg-light border-0 mt-3"
                        placeholder="Your User name"
                        name="user_name"
                        style={{ height: "55px"}}
                   />

                        <div className="col-12 mt-3">
                            <button className="btn btn-info w-100 py-2" type="submit" style={{"color":"white"}}>
                                Login
                            </button>
                        </div>
                </form>
            </div>
        </div>
     </Container>
  );
};

export default LoginPage;

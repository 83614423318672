import React from 'react'

export const Current = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
            <h5 className="fw-bold text-success text-uppercase">ZMDA Members</h5>
            <h1 className="mb-0">The Leaders</h1>
        </div>
        <div className="row g-5">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/p.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-success">Ali Mussa Sultan</h4>
                        <p className="text-uppercase m-0">President</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/vp.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-success">Tawhida Mwinyi Talib</h4>
                        <p className="text-uppercase m-0">Vice President</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/katibu.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-success">Ibrahim Najim Rashid</h4>
                        <p className="text-uppercase m-0">General secretary</p>
                    </div>
                </div>
            </div>
        </div> 
    </div>

    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/mjumbe1.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-success">Khadija Mohammed Khelef</h4>
                        <p className="text-uppercase m-0">Treasure</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/mjumbe2.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-success">Mohammed Juma Salum</h4>
                        <p className="text-uppercase m-0">Members of Commitee</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/mjumbe3.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-success btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-success">Fadhil Nyange Silima</h4>
                        <p className="text-uppercase m-0">Members of Commitee</p>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>
  )
}

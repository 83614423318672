import React from 'react'
import { Nav } from '../../menu/Nav'
import { Slider } from './Slider'
import { Condtion } from './Condtion'
import { Form } from './Form'

export const Registration = () => {
  return (
    <div>
       <div className="container-fluid position-relative p-0">
        <Nav />
       <Slider />
       </div>
       <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
         <div className="container py-5">
            <div className="row g-5">
                <Condtion />
                <Form />
            </div>
        </div>
    </div>
    </div>
  )
}

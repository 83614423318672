import React from 'react'

export const Why = () => {
  return (
    <div>
         <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
                <h3 className="fw-bold text-success text-uppercase">Why Join ZMDA</h3>
                <h1 className="mb-0">We Are Here to Grow Your Professional</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                            <div className="bg-success rounded d-flex align-items-center justify-content-center mb-3" style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-cubes text-white"></i>
                            </div>
                            <h4>CME Credits</h4>
                            <p className="mb-0">Many medical associations offer Continuing Medical Education (CME) opportunities.
                             These activities help members fulfill their continuing education requirements, which are essential 
                             for maintaining professional certifications and licenses.</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                            <div className="bg-success rounded d-flex align-items-center justify-content-center mb-3" style={{"width":"60px","height": "60px"}}>
                                <i className="fa fa-award text-white"></i>
                            </div>
                            <h4>Professional Development</h4>
                            <p className="mb-0"> Medical associations often provide opportunities for continuous learning and 
                            professional development. They may offer seminars, workshops, and conferences where members can stay 
                            updated on the latest advancements in their field.  </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{"min-height": "350px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="user/images/IMG_6563.jpg" style={{"object-fit": "cover"}} />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                            <div className="bg-success rounded d-flex align-items-center justify-content-center mb-3" style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-users-cog text-white"></i>
                            </div>
                            <h4>Networking</h4>
                            <p className="mb-0">Being a part of a medical association allows healthcare professionals to connect 
                            with colleagues, experts, and leaders in their field. Networking can lead to collaborations, mentorship 
                            opportunities, and the exchange of valuable knowledge.</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                            <div className="bg-success rounded d-flex align-items-center justify-content-center mb-3" style={{"width": "60px","height": "60px"}}>
                                <i className="fa fa-phone-alt text-white"></i>
                            </div>
                            <h4>Career Opportunities</h4>
                            <p className="mb-0">Membership in a medical association can enhance professional visibility and 
                            credibility. Some associations provide job boards, career services, and opportunities for
                             leadership roles within the organization, which can benefit one's career.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

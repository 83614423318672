import React from 'react'

export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-8">
                <div className="mb-5">
                    <img className="img-fluid w-100 rounded mb-5" src="user/img/third.jpg" alt="" />
                    <h1 className="mb-4">Meeting with the Previous Leadership</h1>
                    <p>On 21st January 2024, current transitional leaders of Zanzibar Medical and Dental Association (ZMDA)had organized meeting with the Previous Leadership of ZMDA Dr. Ahmed Moh’d Khatib, Dr.Said Ali Said, Dr. Juma Salum Mbwana (Mambi), and Late Chairman of ZMDA Dr. Mwanaheri Ahmed Mahmoud. </p>

                    <p>Aim of the meeting was to acquire background information about the association, get acquainted with the Late ZMDA leaders.</p>

                    <p>Dr.Mwanaheri acknowledged the hard work done by the leadership team of ZMDA and emphasized on consistency throughout to fulfill goals of ZMDA, regardless challenges Association is facing at the moment. </p>
                </div>
                
            </div>

            <div className="col-lg-4">
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                    <div className="input-group">
                        <input type="text" className="form-control p-3" placeholder="Keyword" /> 
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                        <h3 className="mb-0">News</h3>
                    </div>
                    <div className="link-animated d-flex flex-column justify-content-start">
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/detail"><i className="bi bi-arrow-right me-2"></i>Transitional Leadership Selection</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/meeting-with-the-previous-leadership"><i className="bi bi-arrow-right me-2"></i>Meeting with the Previous Leadership</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/intro-zmda"><i className="bi bi-arrow-right me-2"></i>Introduction of ZMDA</a>
                    </div>
                </div>
             
              
            </div>
           
        </div>
    </div>
</div>
  )
}

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';


const Layout = () => {
  const location = useLocation();
  const shouldHideHeaderAndFooter = ['/dashboard', '/login','/registed','/newmember'].includes(location.pathname);

  return (
    <div>
      {!shouldHideHeaderAndFooter && <Header />}
      <main>
        <Outlet />
      </main>
      {!shouldHideHeaderAndFooter && <Footer />}
    </div>
  );
};

export default Layout;


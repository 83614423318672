import React from 'react'

export const TopNav = () => {
  return (
    <nav className="col-2 bg-default pr-3">
                <h1 className="h4 py-3 text-center text-primary">
                {/* <h1 className="m-0"><img src="user/img/zmda.png" width="100" height="auto"  /></h1> */}
                    <span className="d-none d-lg-inline">
                        ZMDA 
                    </span>
                </h1>
                <div className="list-group text-center text-lg-left">
                    <span className="list-group-item disabled d-none d-lg-block">
                        <small>CONTROLS</small>
                    </span>
                    <a href="/dashboard" className="list-group-item list-group-item-action active">
                        <i className="fas fa-home me-2"></i>
                        <span className="d-none d-lg-inline">Dashboard</span>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action">
                        <i className="fas fa-user me-2"></i>
                        <span className="d-none d-lg-inline">Leader / Commitee</span>
                        {/* <span className="d-none d-lg-inline badge bg-danger rounded-pill float-right">20</span> */}
                    </a>

                    <a href="/registed" className="list-group-item list-group-item-action">
                        <i className="fas fa-users me-2"></i>
                        <span className="d-none d-lg-inline">Register User</span>
                        {/* <span className="d-none d-lg-inline badge bg-danger rounded-pill float-right">20</span> */}
                    </a>
                    
                    <a href="#" className="list-group-item list-group-item-action">
                        <i className="fas fa-flag me-2"></i>
                        <span className="d-none d-lg-inline">Reports</span>
                    </a>
                </div>
                {/* <div className="list-group mt-4 text-center text-lg-left">
                    <span className="list-group-item disabled d-none d-lg-block">
                        <small>WEBSITE SETING</small>
                    </span>
                    <a href="#" className="list-group-item list-group-item-action">
                        <i className="fas fa-user"></i>
                        <span className="d-none d-lg-inline">New User</span>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action">
                        <i className="fas fa-edit"></i>
                        <span className="d-none d-lg-inline">Update Data</span>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action">
                        <i className="far fa-calendar-alt"></i>
                        <span className="d-none d-lg-inline">Add Events</span>
                    </a>
                </div> */}
            </nav>
  )
}

import React from 'react'

export const New = () => {
  return (
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-8">
                    <div class="row g-5">

                        <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                <div class="blog-img position-relative overflow-hidden">
                                    <img class="img-fluid" src="user/img/first.jpg" alt="" />
                                    <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">First Meeting</a>
                                </div>
                                <div class="p-4">
                                    <div class="d-flex mb-3">
                                        <small><i class="far fa-calendar-alt text-primary me-2"></i>30 Dec, 2023</small>
                                    </div>
                                    <h4 class="mb-3">Transitional Leadership Selection</h4>
                                    <a class="text-uppercase" href="/detail">Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 wow slideInUp" data-wow-delay="0.9s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                <div class="blog-img position-relative overflow-hidden">
                                    <img class="img-fluid" src="user/img/third.jpg" alt="" />
                                    <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Committee Meeting</a>
                                </div>
                                <div class="p-4">
                                    <div class="d-flex mb-3">
                                        <small><i class="far fa-calendar-alt text-primary me-2"></i>21 Jan, 2024</small>
                                    </div>
                                    <h4 class="mb-3">Meeting with the Previous Leadership</h4>
                                    <a class="text-uppercase" href="/meeting-with-the-previous-leadership">Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 wow slideInUp" data-wow-delay="0.6s">
                            <div class="blog-item bg-light rounded overflow-hidden">
                                <div class="blog-img position-relative overflow-hidden">
                                    <img class="img-fluid" src="user/img/secornd.jpg" alt="" />
                                    <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Zoom Meeting</a>
                                </div>
                                <div class="p-4">
                                    <div class="d-flex mb-3">
                                        <small><i class="far fa-calendar-alt text-primary me-2"></i>24 Feb, 2024</small>
                                    </div>
                                    <h4 class="mb-3 mt-3">Introduction of ZMDA </h4>
                                    <a class="text-uppercase" href="/intro-zmda">Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div class="col-12 wow slideInUp" data-wow-delay="0.1s">
                            <nav aria-label="Page navigation">
                              <ul class="pagination pagination-lg m-0">
                                <li class="page-item disabled">
                                  <a class="page-link rounded-0" href="#" aria-label="Previous">
                                    <span aria-hidden="true"><i class="bi bi-arrow-left"></i></span>
                                  </a>
                                </li>
                                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item">
                                  <a class="page-link rounded-0" href="#" aria-label="Next">
                                    <span aria-hidden="true"><i class="bi bi-arrow-right"></i></span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                        </div> */}
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <div class="input-group">
                            <input type="text" class="form-control p-3" placeholder="Keyword" />
                            <button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                    <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="mb-0">News</h3>
                        </div>
                        <div className="link-animated d-flex flex-column justify-content-start">
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/detail"><i className="bi bi-arrow-right me-2"></i>Transitional Leadership Selection</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/meeting-with-the-previous-leadership"><i className="bi bi-arrow-right me-2"></i>Meeting with the Previous Leadership</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/intro-zmda"><i className="bi bi-arrow-right me-2"></i>Introduction of ZMDA</a>
                    </div>
                    </div>
                    
                   
                </div>
            </div>
        </div>
    </div>
  )
}

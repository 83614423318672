import React from 'react'

export const Slider = () => {
  return (
    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="w-100" src="user/images/INFO.jpg" width="100%" height="100%"  alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{"max-width": "900px"}}>
                        <h1 className="text-white mb-3 animated slideInDown">Zanzibar Medical and Dental Association (ZMDA)</h1>
                        <h4 className="text-white mb-md-4 animated">Vision: To promote the medical and dental services and maintain the honor, dignity and interests of medical, Dental and their allied professionals to the highest standard of medical ethics and conduct </h4>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="user/images/IMG_6564.jpg" width="100%" height="100%" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{"max-width": "900px"}}>
                       <h1 className="text-white mb-3 animated slideInDown">Zanzibar Medical and Dental Association (ZMDA)</h1>
                        <h4 className="text-white mb-md-4 animated">Mission: To act a representative body of the medical and dental allied medical and dental practitioners in Zanzibar and when and where necessary to advise the Government on health matters </h4>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="user/images/IMG_6569.jpg" width="100%" height="100%" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{"max-width": "900px"}}>
                        <h1 className="text-white  mb-3 animated slideInDown">Zanzibar Medical and Dental Association (ZMDA)</h1>
                        <h4 className="text-white mb-md-4 animated">Mission: To ensure, maintain and safeguard the interests, honor, privileges and welfare of its members. </h4>
                   </div>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
  )
}

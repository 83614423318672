import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/img/mambi.jpg"  alt="" />
       <div> Glover at the 2015 <a href="#">Toronto International Film Festival</a>
       </div>
     </td>
     </tr>
     
     <tr>
       <th><strong>Born</strong></th>
       <td>Donald McKinley Glover September 25, 1983 (age 32)Edwards Air Force Base, California, U.S.
       </td>
     </tr>
     
     <tr>
       <th>Residence</th>
       <td>Los Angeles, CA USA
       </td>
     </tr>
     
     <tr>
       <th>Alma Mater</th>
       <td>Tisch School of the Arts</td>
     </tr>
     
     <tr>
       <th>Occupation</th>
       <td> Comedian, Actor, Rapper, Writer </td>
     </tr>
     
     <tr>
       <th>Years active</th>
       <td>2002-Present</td>
     </tr>
     
     <tr>
       <th>Also known as</th>
       <td>Childish Gambino</td>
     </tr>
     
     <tr>
       <th> Origin</th>
       <td> Stone Mountain, GA USA</td>
     </tr>
     
     <tr>
       <th>Genre</th>
       <td>RnB, Hip Hop</td>
     </tr>
     
     <tr>
        <th>Instrument</th>
        <td>Vocals</td>
    </tr>

   </table>
   </div>
      
    <h1> Dr. Juma Salum Mbwana (Mambi)</h1>
    <hr/>
    
    <h4><strong>2006–2010: Acting and music debut</strong></h4>
    <p>From 2006 to 2009, Glover was a writer for the NBC series 30 Rock where he also had occasional cameo appearances. He was presented the Writers Guild of America Award for Best Comedy Series at the February 2009 ceremony for his work on the third season.[10] Glover's stage name, Childish Gambino, came from the Wu-Tang Clan's name generator.[11][12] On June 5, 2008, he released an independent album, titled Sick Boi. Glover would then become a member of the sketch comedy group Derrick Comedy, along with Dominic Dierkes, Meggie McFadden, DC Pierson, and Dan Eckman. The group wrote and starred in a feature-length film, Mystery Team, released in theaters in 2009.
<br />
On September 17, 2009, Childish Gambino released the independent album Poindexter. A pair of mixtapes, titled I Am Just a Rapper and I Am Just A Rapper 2 were released, in close succession in 2010. Robert Scahill added his producing expertise helping with the majority of the tracks. The track listings for those mixtapes consist of the name of the song "he" raps, followed by the song he raps over. His second album, Culdesac, was set to be released on July 2, 2010, but a couple of last minute additions caused the album to be delayed for a day. The album was made available on July 3.[13] Glover has stated in interviews that on Sick Boi and Poindexter he felt he had to hide behind gimmicks, such as pink hoodies, but with his subsequent projects, he has touched on more personal subject matter, including family, schoolyard bullying, troubled romantic relationships, suicidal thoughts and alcoholism. He has disowned his 2005[14] album, The Younger I Get, as the too-raw ramblings of what he calls a "decrepit Drake."
<br />
Glover's stand-up special aired on Comedy Central on March 19, 2010. In May 2010, a fan suggested Glover for the role of Peter Parker in the then-upcoming The Amazing Spider-Man film, encouraging his supporters to retweet the hashtag "#donald4spiderman".[15] The campaign, originally started to see how far social networking could carry a message, quickly gained a large following.[16] The call for Glover to be allowed to audition for the role was supported by Spider-Man creator Stan Lee.[17] Glover was not awarded an audition and the role instead went to Andrew Garfield. He would later reveal that he was never contacted by anyone from Sony Pictures for the role. Comics writer Brian Michael Bendis, who announced an African-American version of Spider-Man a year later, said he had conceived of the character before Glover's campaign went viral.[18] Bendis gave credit to Glover for influencing the new hero's looks for Spider-man after seeing him dressed as Spider-Man on Community (a nod to the campaign), Bendis said, "I saw him in the costume and thought, 'I would like to read that book.'"[19] Glover would later voice this incarnation of Spider-Man on the Ultimate Spider-Man animated series.[20]
<br />
Glover received the Rising Comedy Star award at the Just for Laughs festival in July 2010.[21] Glover was featured in Gap's 2010 Holiday ad campaign. He DJs and produces electronic music under the moniker "mcDJ" (pronounced "M-C-D-J"). His music is often made available for free download via his website.[citation needed]
<br />
On December 1, 2010, Glover released the first track off of his EP titled "Be Alone". He released the next track, "Freaks and Geeks", as well as a five-song track list, on February 11, 2011. On February 25, Glover released the dates for the IAMDONALD tour and also his very first music video for "Freaks and Geeks".[22] The music video was shot by Dan Eckman, the director of the Derrick Comedy troupe. This song was later used in an Adidas commercial featuring Dwight Howard. On March 8, 2011 Glover released the EP via his official website.[23]</p>
      
      
      
    </div>  
    </div>
  )
}

import React from 'react'
import { TopNav } from './TopNav'


export const Dashboar = () => {
    
  return (
    <div className="container-fluid">
        <div className="row g-0">
            <TopNav />
            <main className="col-10 bg-defaut">
                
                <div className="container-fluid mt-3 p-4">
                    <div className="row mb-3">
                        <div className="col">
                            <div className="alert alert-info">

                                <div className='row'>

                                    <div className='col-xl-3 col-sm-6 col-12'>
                                            <div class="card">
                                            <div class="card-content">
                                                <div class="card-body">
                                                <div class="media d-flex">
                                                    <div class="align-self-center">
                                                    <i className="fas fa-flag primary font-large-6 float-left"></i>
                                                    </div>
                                                    <div class="media-body text-right">
                                                      <h3 className='text-primary'>278</h3>
                                                    <h4>New Posts</h4>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                
            </main>
        </div>
    </div>
  )
}

import React from 'react'
import { About } from './About'
import { Member } from './Member'
import { Why } from './Why'
import { Patner } from './Patner'
import { Nav } from '../../menu/Nav'
import { Slider } from './Slider'
import { News } from './News'

export const Home = () => {
  return (
    <div>
      <div className="container-fluid position-relative p-0">
      <Nav />
      <Slider />
      </div>
      <About />
      <Member />
      <Why />
      <News />
      <Patner />
    </div>
  )
}

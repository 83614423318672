import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"10em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b1.jpg"  alt="" />
     </td>
     </tr>
     
      
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>16/08/1972
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Fuoni Zanzibar </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 773 724 149</td>
     </tr> */}
     


   </table>
   </div>
      
    <h1>Dr Said Ali Said </h1>
    <hr/>
    
    <h4><strong>EDUCATIONAL BACKGROUND</strong></h4>
      <ul>
          <li>1977-1990: Quran Madrasa Education at Ndijani Village Zanzibar</li>
          <li>1979-1989	Primary	 and Secondary education at Ndijani Secondary School Zanzibar</li>
          <li>1990 O-Level education at Lumumba Sec School Zanzibar. National Form IV Certificate of Education.</li>
          <li>1990- 1991 Civil Service JKU	</li>
          <li>1991-1993	Advanced Level Education at Lumumba sec school National Form VI Certificate of Education.</li>
          <li>1994-1997	Diploma in Clinical medicine at Clinical Officer Training Centre KIBAHA </li>
          <li>2002-2007 Undergraduate Medical Education at the College of Medicine,  Hubert Kairuki Memorial University Dar Es Salaam Tanzania</li>
          <li>2007-2008	Internship programme  at Bugando Medical Centre. Mwanza Tanzania.</li>
          <li>2009-2012	Postgraduate Medical Training in General Surgery at the Catholic University Of Health and Allied Sciences- Bugando </li>
      </ul> 

      <h4><strong>OTHER COURSES ATTENDED</strong></h4>
        <ul>
            <li>1ST KCMC VascularAccess Immersion :The Royal College of Surgeons of England, KCMC Tanzania 2022</li>
            <li>Workshop on University Governance	And Quality Assurance System for University Heads of Academic department: The Tanzania Commission for Universities, Mzinga Corporation, Morogoro 9TH – 10Th June 2022</li>
            <li>Foundation on Non-operative Fracture Treatment: AO Alliance foundation 13 – 15 June2016, Zanzibar</li>
            <li>Mesh Herniorrhapy:	Johnson & Johnson Professional Export Ethicon 2016</li>
            <li>Surgical skills: The Royal college of Surgeons of England RCS 2010 at Bugando Certificate in facilitating Essential Surgical Skills ESS under the Auspices of CNIS and ACC-ESS 2011</li>
            <li>Introduction of Laparoscopic surgery: Arusha Lutheran Medical Centre 31 October – 3 November 2011</li>
            <li>Qualified to Teach Essentil surgical Skill (ESS):	Acc – Ess Afican and Canadian Committee fo ESS May 2011 – April 2014</li>
            <li>Workshop on	 Circumcision:	Mnazi Mmoja Hospital Zanzibar March 2014</li>
            <li>Urolink Workshop On Basic Urology:	The British Association of Urological Surgeons 11 – 14 May 2009</li>
            <li>COSECSA/ASEA	Regional Meeting 	And Scientific Conference: Tanzania Surgical Association & College of Surgeons of east and Southern Africa 6 – 7  August 2009</li>
            <li>Sexual Transmitted Infections	Medicos Del Mundo 27 – 30 August 2002</li>
        </ul>

      <h4><strong>APPOINTMENTS AND PROFESSIONAL EXPERIENCE</strong></h4>
          <ul>
              <li>1997-2000		Clinical Officer In charge, Chumbuni Dispensary Zanzibar</li>
              <li>2000 - 2002		Assistance in charge, Makunduchi Cottage Hospital</li>
              <li>2003 - 2004		MD Class Representative HKMU</li>
              <li>2007 - 2009		Assistance in charge Derpartment of Surgery Mnazi Mmoja Referral Hospital</li>
              <li>2012 	  Part time Lecturer Zanzibar University </li>
              <li>2013 to date		Part time Lecturer Zanzibar College of Health Sciences</li>
              <li>2018 to date		Lecturer State University of Zanzibar</li>
              <li>19 – 20 March 2022	Participate in the Family Health	 Bonanza</li>
              <li>2023	Associate dean school of health and medical sciences - SUZA</li>
          </ul>

         <h4><strong>PUBLICATIONS</strong></h4>
              <ul>
                  <li>2012			Allergic rhinitis and its co morbidities at Bungando Medical Centre in 
                        Northern West Tanzania a prospective review of 190 cases published
                        By BMC Journal</li>
                  <li>2022	A Study to Determine the Prevalence of Traumatic Head Injuries in Zanzibar: Case Study of Mnazi Mmoja Hospital published by Journal of Advances in Medicine and Medical Research. pp. 62-67. ISSN 2456-8899 </li>
              </ul>
    </div>  
    </div>
  )
}


import React, { useState }  from 'react'
import { Nav } from '../../menu/Nav'
import { Slider } from './Slider'

export const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    
        // Clear the error for the specific field when the user corrects the input
        if (errors[name]) {
          setErrors({
            ...errors,
            [name]: ''
          });
        }
      };
    
      const validate = () => {
        const newErrors = {};
    
        if (!formData.name.trim()) {
          newErrors.name = 'Please enter your name.';
        }
    
        if (!formData.email.trim()) {
          newErrors.email = 'Please enter your email.';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'Please enter a valid email address.';
        }
    
        if (!formData.subject.trim()) {
          newErrors.subject = 'Please enter a subject.';
        }
    
        if (!formData.message.trim()) {
          newErrors.message = 'Please enter your message.';
        }
    
        return newErrors;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
          // Submit the form
          console.log('Form data:', formData);
        } else {
          setErrors(validationErrors);
        }
      };

  return (
    <div>
        <div className="container-fluid position-relative p-0">
            <Nav />
            <Slider />
        </div>
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
            <h3 class="fw-bold text-success text-uppercase">Contact Us</h3>
            <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
        </div>
        <div class="row g-5 mb-5">
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                    <div class="bg-success d-flex align-items-center justify-content-center rounded" style={{"width": "60px","height": "60px"}}>
                        <i class="fa fa-phone-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Call to ask any question</h5>
                        <h4 class="text-success mb-0">+255 778 009 859</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                    <div class="bg-success d-flex align-items-center justify-content-center rounded" style={{"width": "60px","height": "60px"}}>
                        <i class="fa fa-envelope-open text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Email to get free quote</h5>
                        <h4 class="text-success mb-0">info@zmda.or.tz</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                    <div class="bg-success d-flex align-items-center justify-content-center rounded" style={{"width": "60px","height": "60px"}}>
                        <i class="fa fa-map-marker-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Visit our office</h5>
                        <h4 class="text-success mb-0">71103 Mitondooni, Zanzibar</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-5">
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-md-6">
                    <input
                        type="text"
                        name="name"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Your Name"
                        style={{ height: '55px' }}
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>
                    <div className="col-md-6">
                    <input
                        type="email"
                        name="email"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Your Email"
                        style={{ height: '55px' }}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <div className="text-danger">{errors.email}</div>}
                    </div>
                    <div className="col-12">
                    <input
                        type="text"
                        name="subject"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Subject"
                        style={{ height: '55px' }}
                        value={formData.subject}
                        onChange={handleChange}
                    />
                    {errors.subject && <div className="text-danger">{errors.subject}</div>}
                    </div>
                    <div className="col-12">
                    <textarea
                        name="message"
                        className="form-control border-0 bg-light px-4 py-3"
                        rows="4"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    {errors.message && <div className="text-danger">{errors.message}</div>}
                    </div>
                    <div className="col-12">
                    <button className="btn btn-success w-100 py-3" type="submit">
                        Send Message
                    </button>
                    </div>
                </div>
                </form>
            </div>
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
               
            </div>
        </div>
    </div>
</div>
</div>
  )
}

import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b3.jpg"  alt="" />
     </td>
     </tr>
     
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>20/8/1959
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Mwera </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 777 432 385</td>
     </tr> */}

   </table>
   </div>
      
    <h1> Dr. Mwatima Abdallah Juma</h1>
    <hr/>
    
    <h4><strong>Founder ZMDA 2001 - Present</strong></h4>
    <p> Mwatima Abdalla Juma is a Tanzanian agricultural expert with extensive
        experience in rural development, research, and organic agriculture. She holds a
        PhD in Agriculture from Wye College, London University, specializing in
        Coconut Physiology and Tissue Culture. With over 45 years of involvement in
        tropical agricultural development, she has managed multidisciplinary projects,
        collaborated with international organizations, and contributed significantly to
        policy dialogue and sector-wide approaches.<br /></p><p>
        Mwatima's career highlights include serving as the Director of Msonge Organic
        Family Farm in Zanzibar, where she promotes organic farming practices and
        supports smallholder producers. She has also held key positions such as Senior
        IFAD Country Programme Officer, Commissioner for Agriculture, Research,
        and Extension in Zanzibar, and Deputy Project Coordinator for the National
        Coconut Development Programme.<br /></p><p>
        A pioneer in advocating for organic agriculture in Tanzania, Mwatima has
        represented Africa on the World Board of the International Federation of
        Organic Agriculture Movement (IFOAM), chaired the Tanzania Organic
        Agriculture Movement, and contributed to the development of East African
        Organic Standards. She is also involved in various advisory boards and
        councils, including the Presidential Food and Agriculture Advisory Council and
        the International Board of Farm Radio International.<br /></p><p>
        With expertise in project management, research, and policy development,
        Mwatima continues to be a driving force in promoting sustainable agricultural
        practices and empowering rural communities in Tanzania.</p>
    </div>  
    </div>
  )
}

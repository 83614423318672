import React from 'react'

export const Slider = () => {
  return (
    <div className="container-fluid bg-primary py-5 bg-header" style={{"margin-bottom": "90px"}}>
    <div className="row py-5">
     
    </div>
</div>
  )
}

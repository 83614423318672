import React from 'react'
import { Current } from './Current'
import { Privios } from './Privios'
import { Board } from './Board'
import { Nav } from '../../menu/Nav'
import { Slider } from './Slider'

export const Leader = () => {
  return (
    <div>
       <div className="container-fluid position-relative p-0">
        <Nav />
        <Slider />
       </div>
        <Current />
        <Board />
        {/* <Privios /> */}
    </div>
  )
}

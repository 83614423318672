import React from 'react'

export const Founder = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
            {/* <h3 className="fw-bold text-uppercase title-header">Team Members</h3> */}
            <h1 className="mb-0">The Founder of ZMDA</h1>
        </div>
        <div className="row g-5">

        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b4.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                        <a href='/mwanaheri-ahmed-abdulrahim-mahmoud'>
                            <div className="text-center py-4">
                                <h4 className="text-success">Dr. Mwanaheri Ahmed Abdulrahim Mahmoud</h4>
                                <p className="text-uppercase m-0">President</p>
                            </div>
                        </a>
                </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b2.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <a href='/ahmed-mohammed-khatib'>
                        <div className="text-center py-4 mt-4">
                            <h4 className="text-success">Dr. Ahmed Mohammed Khatib</h4>
                            <p className="text-uppercase m-0">Vice President</p>
                        </div>
                    </a>
                </div>
            </div>

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b1.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                        <a href='/said-ali-said'>
                            <div className="text-center py-4 mt-4">
                                <h4 className="text-success">Dr. Said Ali Saidi</h4>
                                <p className="text-uppercase m-0">General secretary</p>
                            </div>
                        </a>
                </div>
            </div>
        </div>
    </div>


    <div className="container py-5">
        <div className="row g-5">

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b5.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <a href='azza-amir-said'>
                        <div className="text-center py-4">
                            <h4 className="text-success">Dr. Azzah Amin Said</h4>
                            <p className="text-uppercase m-0">Treasure</p>
                        </div>
                    </a>
                </div>
            </div>

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/img/mambi.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <a href='/Juma-salum-mbwana'>
                    <div className="text-center py-4">
                        <h4 className="text-success">Dr Juma Salum Mbwana</h4>
                        <p className="text-uppercase m-0">Members of Commitee</p>
                    </div>
                    </a>
                </div>
            </div>
           
        </div>
    </div>
</div>
  )
}

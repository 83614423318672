import React from 'react'
import { TopNav } from './TopNav'

export const AdminReg = () => {
  return (
    <div className="container-fluid">
    <div className="row g-0">
        <TopNav />
        <main className="col-10 bg-defaut">
            
            <div className="container-fluid mt-3 p-4">
                <div className="row mb-3">
                    <div className="col">
                        <div className="alert alert-info">
                            
                        </div>
                    </div>
                </div>
                </div>
            
        </main>
    </div>
</div>
  )
}

import React from 'react'

export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-8">
                <div className="mb-5">
                    <img className="img-fluid w-100 rounded mb-5" src="user/images/INFO.jpg" alt="" />
                    <h1 className="mb-4">Transitional Leadership Selection</h1>
                    <p>This meeting was held on 30th of december 2023 at the RCH hall , it was participated with founders of the ZMDA 
                        and the purpose was to bring awareness of the ZMDA and to choose the Transitional leaders who can activate the 
                        Association and make it as a functional tool.
                    </p>

                    <p>The module of the constitution was elaborated by DR Juma Salum Mbwana (MAMBI) one amoung the founder of ZMDA and DR Said Ali Said (Mkarafuu) 
                        the former general secretatry of ZMDA.
                    </p>

                    <p>The Transitional leadership was appointed by votes  where by the President of ZMDA is Dr Ali Mussa Sultan  , Vice president of ZMDA is Dr Tawhida Mwinyi Talib,
                        General secretary of ZMDA is Dr Ibrahim Najim Rashid , Treasure of ZMDA is Dr Khadija Mohammed Khelef , Members of Commitee of ZMDA are Dr Mohammed Juma Salum (MAMBI),Dr Ayman Mahfoudh Haji , Dr Fadhil Nyange Silima
                    </p>

                    <p>The meeting was closed by leaving the Transitional Leaders too take action and make the Association to be active and brings the impact in Zanzibar .</p>
                </div>
                
            </div>

            <div className="col-lg-4">
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                    <div className="input-group">
                        <input type="text" className="form-control p-3" placeholder="Keyword" /> 
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                        <h3 className="mb-0">News</h3>
                    </div>
                    <div className="link-animated d-flex flex-column justify-content-start">
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/detail"><i className="bi bi-arrow-right me-2"></i>Transitional Leadership Selection</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/meeting-with-the-previous-leadership"><i className="bi bi-arrow-right me-2"></i>Meeting with the Previous Leadership</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/intro-zmda"><i className="bi bi-arrow-right me-2"></i>Introduction of ZMDA</a>
                    </div>
                </div>
             
              
            </div>
           
        </div>
    </div>
</div>
  )
}

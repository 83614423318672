import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/img/mambi.jpg"  alt="" />
       {/* <div> Glover at the 2015 <a href="#">Toronto International Film Festival</a>
       </div> */}
     </td>
     </tr>
     
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>20/8/1959
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Mwembetanga </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 777 432 385</td>
     </tr> */}
     

   </table>
   </div>
      
    <h1> Dr. Juma Salum Mbwana (Mambi)</h1>
    <hr/>
    
    <h4><strong>Educational back ground</strong></h4>
     <ul>
        <li>Internship training 2011-2012  Kilimanjaro Christian Medical Centre </li> 
        <li>Doctor of Medicine 2005-2010 Hubert Kairuki Memorial Universty</li>  
        <li>Pre University Program Feb 2004 to July 2004 Hubert Kairuki Memorial Universty</li> 
        <li>Short course on Paediatric Cardiology in Israel 2000 for a period of six months</li>   
        <li>Advance Diploma in Radiology 1994-1996 Kilimanjaro Christian Medical Centre</li>   
        <li>Advance diploma in clinical medicine (AMO) 1987-1989 Kilimanjaro Christian Medical Centre</li>   
        <li>Medical assistant course 1979-1982 MATC -Tanga </li>   
        <li>Secondary  School: Kiponda /Lumumba Secondary School 1973-1976</li>   
        <li>Primary  School:  Vikokotoni Primary School  1966-1972</li>    
     </ul>
      
     <h4><strong>Working experience:</strong></h4>
     <ul>
        <li>28th April 2021 – 27th April 2024 Tanzania atomic Energy Commission (TAEC) Board Member and Chairman of Training,Technical and Research Board  Committee </li> 
        <li>2018- 2021  Director of Curative Services Ministry of Heaith, Zanzibar</li>  
        <li>1998  to 2000 Chief Training Officer in the Ministry of Health Zanzibar</li> 
        <li>In 1993  Incharge of Chake Chake Hospital in Pemba for seven months</li>   
        <li>In 1991 to 1992 posted to be overall incharge of Kivunge Cottage Hospital and the head of District Health Management Team for strengthening and keep it in motion</li>   
        <li>In 1989 to 1991as Assistant Medical officer hold a post of an incharge of the Neonatal Unit at Mnazi Mmoja Hospital</li>   
        <li>In 1982  to 1987 Working in the department of pediatric with Consultant pediatrician belong to   Save the Children Dr Betty Burgess for six years at Mnazi Mmoja Hospital as Medical Assistant </li>   
     </ul>

     <h4><strong>Research:</strong></h4>
       <ul>
        <li>To find out the type of emergence case for a period of six months at Mnazi Mmoja Hospital (2012) at emergence clinic.</li>
        <li>To be involved in determine the prevalence of Malaria parasite in underfive at Mnazi Mmoja Hospital ( 1980s) in Cot ward A.</li>
       </ul>
      <h4><strong>Seminar</strong></h4><ul><li>HIV ,Possible Serious Bacterial Infection</li></ul>

      <h4><strong>MEMBERSHIP TO NGO:</strong></h4> 
       <ul>
        <li>Founder of Zanzibar Medical and Dental Assosciation 2001.</li>
        <li>Mwembetanga Youth Organization started 19/03/2019</li>
       </ul>
      <h4><strong>Participation on establishment of Medical services improvement in Zanzibar.</strong></h4>
      
      <ul>
        <li>Establishment of emergency department at Mnazi mmoja Hospital 2012</li>
        <li>Started  Intensive Care Unit at Mnazimmoja Hospital 1999</li>
        <li>Introduce Ultrasound at Mnazi mmoja hospital 1998</li>
        <li>Upgrading MCH Aider to PHN B Nurse 1998</li>
        <li>Organise the Training of Clinical Officer Programme at Mbweni College of Health Science(Medical Assistant Course )1998</li>
      </ul>

    </div>  
    </div>
  )
}
	






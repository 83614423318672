import React, { useRef, useState } from 'react';
import axios from 'axios'; // Import axios

export const Form = () => {
    const form = useRef();

    const [isZMCRegistered, setIsZMCRegistered] = useState(false);
    const [isStudent, setIsStudent] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        full_name: '',
        age: '',
        adress: '', 
        phone: '',
        cadre: '',
        working_center: '',
        gender: '',
        zmc_no: '',
        email: '',
        student_name: '',
        student_year: '',
        image: null // Changed to null to handle file upload
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    image: file // Storing file object for upload
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: '',
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const ZMChandleSelectChange = (e) => {
        setIsZMCRegistered(e.target.value === 'Yes');
        setFormData((prevFormData) => ({
            ...prevFormData,
            zmc_no: e.target.value === 'Yes' ? prevFormData.zmc_no : '',
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            zmc_no: '',
        }));
    };

    const studentHandleSelectChange = (e) => {
        setIsStudent(e.target.value === 'Yes');
        setFormData((prevFormData) => ({
            ...prevFormData,
            student_name: e.target.value === 'Yes' ? prevFormData.student_name : '',
            student_year: e.target.value === 'Yes' ? prevFormData.student_year : '',
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            student_name: '',
            student_year: '',
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.full_name.trim()) newErrors.full_name = 'Please enter your name.';
        if (!formData.age.trim()) newErrors.age = 'Please enter your age.';
        if (!formData.adress.trim()) newErrors.adress = 'Please enter your adress.';
        if (!formData.image) newErrors.image = 'Please upload an image.';
        if (!formData.phone.trim()) newErrors.phone = 'Please enter your phone number.';
        if (!formData.cadre.trim()) newErrors.cadre = 'Please select your cadre.';
        if (!formData.working_center.trim()) newErrors.working_center = 'Please enter your working center.';
        if (!formData.gender.trim()) newErrors.gender = 'Please select your gender.';
        if (!formData.email.trim()) {
            newErrors.email = 'Please enter your email.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email adress.';
        }
        if (isZMCRegistered && !formData.zmc_no.trim()) newErrors.zmc_no = 'Please enter your ZMC registration number.';
        if (isStudent) {
            if (!formData.student_name.trim()) newErrors.student_name = 'Please enter your School.';
            if (!formData.student_year.trim()) newErrors.student_year = 'Please enter your year of study.';
        }

        return newErrors;
    };

    const uploadProduct = async () => {
        const formDataToSubmit = new FormData();
        for (const key in formData) {
            if (formData[key]) {
                formDataToSubmit.append(key, formData[key]);
            }
        }

        try {
            const response = await axios.post('http://localhost:8000/api/createMember', formDataToSubmit, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200) {
                alert('Form submitted successfully!');
                setFormData({
                    full_name: '',
                    age: '',
                    adress: '', 
                    phone: '',
                    cadre: '',
                    working_center: '',
                    gender: '',
                    zmc_no: '',
                    email: '',
                    student_name: '',
                    student_year: '',
                    image: null
                });
                setSelectedImage(null);
            }
        } catch (error) {
            console.error('Failed to submit the form:', error);
            alert('Failed to submit the form. Please try again later.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = validateForm();
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            await uploadProduct();
        }
    };

    return (
        <div className="col-lg-8">
            <div className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                <form ref={form} onSubmit={handleSubmit}>
                    <div className="row g-3">
                        <div className="col-xl-12">
                            <input
                                type="text"
                                className="form-control bg-light border-0"
                                placeholder="Your Name"
                                name="full_name"
                                value={formData.full_name}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            />
                            {errors.full_name && <div className="text-danger">{errors.full_name}</div>}
                        </div>
                        <div className="col-xl-12">
                            <input
                                type="text"
                                className="form-control bg-light border-0"
                                placeholder="Your Address"
                                name="adress"
                                value={formData.adress}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            />
                            {errors.adress && <div className="text-danger">{errors.adress}</div>}
                        </div>
                        <div className="col-12">
                            <input
                                type="number"
                                className="form-control bg-light border-0"
                                placeholder="Your Age"
                                name="age"
                                value={formData.age}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                                max={9999999999}
                            />
                            {errors.age && <div className="text-danger">{errors.age}</div>}
                        </div>
                        <div className="col-12">
                            <input
                                type="tel"
                                className="form-control bg-light border-0"
                                placeholder="Your Phone Number start with 0"
                                maxLength={10}
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            />
                            {errors.phone && <div className="text-danger">{errors.phone}</div>}
                        </div>
                        <div className="col-12">
                            <select
                                className="form-select bg-light border-0"
                                name="cadre"
                                value={formData.cadre}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            >
                                <option value="">Select Your Cadre</option>
                                <option value="Medical Specialist">Medical Specialist</option>
                                <option value="Medical Doctor">Medical Doctor</option>
                                <option value="Assistant Medical Officer">Assistant Medical Officer</option>
                                <option value="Clinical Officer">Clinical Officer</option>
                                <option value="Dental Specialist">Dental Specialist</option>
                                <option value="Dental Officer">Dental Officer</option>
                                <option value="Dental Therapist">Dental Therapist</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.cadre && <div className="text-danger">{errors.cadre}</div>}
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                className="form-control bg-light border-0"
                                placeholder="Your working Center"
                                name="working_center"
                                value={formData.working_center}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            />
                            {errors.working_center && <div className="text-danger">{errors.working_center}</div>}
                        </div>
                        <div className="col-12">
                            <input
                                type="email"
                                className="form-control bg-light border-0"
                                placeholder="Your Email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <div className="col-12">
                            <select
                                className="form-select bg-light border-0"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                style={{ height: '55px' }}
                            >
                                <option value="">Select Sex</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.gender && <div className="text-danger">{errors.gender}</div>}
                        </div>
                        <div className="col-12">
                            <select
                                className="form-select bg-light border-0"
                                style={{ height: '55px' }}
                                onChange={ZMChandleSelectChange}
                            >
                                <option>Are you registered by ZMC?</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {errors.zmc_no && isZMCRegistered && <div className="text-danger">{errors.zmc_no}</div>}
                        </div>
                        {isZMCRegistered && (
                            <div className="col-12">
                                <input
                                    type="text"
                                    className="form-control bg-light border-0"
                                    placeholder="Your ZMC registration number"
                                    name="zmc_no"
                                    value={formData.zmc_no}
                                    onChange={handleInputChange}
                                    style={{ height: '55px' }}
                                />
                            </div>
                        )}
                        <div className="col-12">
                            <select
                                className="form-select bg-light border-0"
                                style={{ height: '55px' }}
                                onChange={studentHandleSelectChange}
                            >
                                <option>Are you a student?</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                        {isStudent && (
                            <>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        placeholder="Your School"
                                        name="student_name"
                                        value={formData.student_name}
                                        onChange={handleInputChange}
                                        style={{ height: '55px' }}
                                    />
                                    {errors.student_name && <div className="text-danger">{errors.student_name}</div>}
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        className="form-control bg-light border-0"
                                        placeholder="Year of Study"
                                        name="student_year"
                                        value={formData.student_year}
                                        onChange={handleInputChange}
                                        style={{ height: '55px' }}
                                    />
                                    {errors.student_year && <div className="text-danger">{errors.student_year}</div>}
                                </div>
                            </>
                        )}
                        <div className="col-12">
                            <input
                                type="file"
                                className="form-control bg-light border-0"
                                style={{ height: '55px' }}
                                onChange={handleFileChange}
                                name="image"
                            />
                            {errors.image && <div className="text-danger">{errors.image}</div>}
                            {selectedImage && (
                                <img src={selectedImage} alt="Selected" style={{ marginTop: '10px', maxHeight: '200px' }} />
                            )}
                        </div>
                        <div className="col-12">
                            <button className="btn btn-secondary w-100 py-3" type="submit">
                                Register
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

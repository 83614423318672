import React from 'react'

export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-8">
                <div className="mb-5">
                    <img className="img-fluid w-100 rounded mb-5" src="user/img/secornd.jpg" alt="" />
                    <h1 className="mb-4">Introduction of ZMDA</h1>
                    <p>First Zoom meeting, held on February 24th 2024. Main agenda was to re-introduce Zanzibar Medical and Dental Association to the community of Doctors and associates.</p>

                    <p>Over 30 participants combined Clinical officers, Medical doctors, Dental therapists, Doctors of dental surgery, Physicians and Surgeons all together.</p>

                    <p>Throughout momentous presentations by Vice chairman Dr. Tawhida M. Talib, following Chairman of ZMDA Dr. Ali Mussa Sultan emphasized some of big pros of our association including to protect rights and interests of its members, conduct researches, and to improve health care system in Zanzibar. </p>

                    <p>Ongoing preparations of the website and registration of members of ZMDA which will be done online through the website.</p>
                    <p>Moreover, ZMDA Chairman  Dr. Sultan accentuated that Zanzibar Medical and Dental Association is non-profitable organization and it main Goal is crystal clear that it is for the Medical, dental practitioners and for the well being of Zanzibar healthcare system.</p>
                </div>
                
            </div>

            <div className="col-lg-4">
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                    <div className="input-group">
                        <input type="text" className="form-control p-3" placeholder="Keyword" /> 
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                        <h3 className="mb-0">News</h3>
                    </div>
                    <div className="link-animated d-flex flex-column justify-content-start">
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/detail"><i className="bi bi-arrow-right me-2"></i>Transitional Leadership Selection</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/meeting-with-the-previous-leadership"><i className="bi bi-arrow-right me-2"></i>Meeting with the Previous Leadership</a>
                        <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="/intro-zmda"><i className="bi bi-arrow-right me-2"></i>Introduction of ZMDA</a>
                    </div>
                </div>
             
              
            </div>
           
        </div>
    </div>
</div>
  )
}

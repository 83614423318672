import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b7.jpg"  alt="" />
     </td>
     </tr>
     
      
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>02/01/1938
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Fuoni </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 777 432 385</td>
     </tr> */}
     

   </table>
   </div>
      
    <h1> Mr. Ahmed Maulid Haji</h1>
    <hr/>
    
    <h4><strong>Educational background</strong></h4>
    <ul>
      <li>Short courses in International Relation and Diplomasia</li>
      <li>Saint Joseph School -Dar es Salaam completed Form 4 Cambridge 1957-1959</li>
      <li>Scholarship in Madagascar for Secondary Education 1948- 1956</li>
      <li>Saint Joseph Nursery School until Starndard  Three  -1944-1948</li>
    </ul>
      
    <h4><strong>Working experience</strong></h4>
      <ul>
        <li>Lecturer – in  French Language and Consular Relations School of Diplomasia  in Dar es Salaam -1993- 2000</li>
        <li>Retired as Principal Foreign  Service officer grade 1 in 1993</li>
        <li>Head of Western Europe in the Ministry of Foreign Affairs – Dar es Salaam 1986-1993</li>
        <li>Minister Counsellor Embassy of Tanzania In France  1979-1985</li>
        <li>Deputy High Commissioner of Tanzania in Nigeria 1977-1979</li>
        <li>Chief  Protocol Officer and Incharge of Foreign Affairs in Zanzibar and official Interpreter  of President of the United Republic Of Tanzania and Presidenit of Zanzibar 1964-1977</li>
      </ul>
      
      <h4><strong>Languages</strong></h4>
      <ul>
        <li>Speaking fluently Swahili</li>
        <li>Speaking fluently English</li>
        <li>Speaking fluently French</li>
        <li>Speaking fluently Comoros</li>
      </ul>
      <h6><strong>International Conference attended based on  Economy,Politics,Foreign Policy,River Nile Basin Discussion.</strong></h6>
    </div>  
    </div>
  )
}



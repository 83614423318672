import React from 'react'
import { Nav } from '../../menu/Nav'
import { Slider } from './Slider'
import { Detail } from './Detail'
export const Biography = () => {
  return (
    <div>
         <div className="container-fluid position-relative p-0">
            <Nav />
            <Slider />
         </div>
         <Detail />
    </div>
  )
}
import React from 'react'

export const Patner = () => {
  return (
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5 mb-5">
            <div class="bg-white">
                <div class="owl-carousel vendor-carousel">
                    <img src="user/img/zmda.png" alt="" />
                    <img src="user/img/vendor-1.jpg" alt="" />
                    <img src="user/img/vendor-2.jpg" alt="" />
                    <img src="user/img/vendor-3.jpg" alt="" />
                    <img src="user/img/vendor-4.jpg" alt="" />
                    <img src="user/img/vendor-5.jpg" alt="" />
                    <img src="user/img/vendor-6.jpg" alt="" />
                    <img src="user/img/vendor-7.jpg" alt="" />
                    <img src="user/img/vendor-8.jpg" alt="" />
                    <img src="user/img/vendor-9.jpg" alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"10em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b5.jpg"  alt="" />
     </td>
     </tr>
     
      
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>20/8/1959
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Fuoni </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 777 278 761</td>
     </tr> */}
     


   </table>
   </div>
      
    <h1>Dr. Azzah Amin Said</h1>
    <hr/>
    
    <h4><strong>2001 - Present Founder ZMDA</strong></h4>
    <p> Azzah Amin Said Nofly is working with UNFPA as Programme Specialist on Sexual Reproductive Health and HIV (2013 to date).</p>
    <p>She has been working in the area of Reproductive and Maternal Health for over the past 20 years. </p>
    <p>She worked with a Ministry of Health – Zanzibar as general   practitioner 1989 – 1996 and then with Marie Stopes Tanzania as Health Facility in Charge - Zanzibar (1997 – 2002). </p>
    <p>Thereafter, she returned to the Ministry of Health and worked as a Reproductive Health Coordinator (2002 – 2013). </p>
    <p>During the period, Azzah contributed to improving reproductive and maternal health in Zanzibar. </p>
    <p>On the other hand, Azzah was a member of Zanzibar Medical and Dental Association in Zanzibar and served as a treasurer from ….. to …….</p>
    <p>Azzah has Master’s in International Health (MIH) from Copenhagen University, Denmark, and Advanced Diploma in Medicine from Mbeya Medical Training Centre. 
    </p><p>She is married with one Child.
    </p><br /><br /><br /> <br />     
      
      
    </div>  
    </div>
  )
}

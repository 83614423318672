import React from 'react'

export const History = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h3 className="fw-bold text-uppercase title-header">About Us</h3>
                        <h1 className="mb-0">ZANZIBAR MEDICAL AND DENTAL ASSOSIATION (ZMDA) ZANZIBAR </h1>
                    </div>
                    <p className="mb-4">
                    Medical and dental associations are typically organizations formed by healthcare professionals,
                     including doctors and dentists, to promote collaboration, professional development, and 
                     the interests of their members. These associations often engage in activities such as organizing 
                     conferences, providing educational resources, advocating for healthcare policies, and facilitating 
                     networking opportunities among members.
                    </p>
                    <p>The name of the association shall be “Zanzibar Medical & Dental Association” or ZMDA.</p>
                    <p>ZMDA was established in Zanzibar 16th January 2001 registered under the societies Act No.6 of 1995 with about few members from medical and Dental professionals.</p>
                    <p>The main objectives are to promote the medical and dental services and maintain the honor, dignity and interest of the medical, dental and their allied professional to the highest standard of medical ethics and conduct.</p>
                    <p>The status of the association is non-governmental, non-religious, not for profit and non-partisan Associations. The association shall have perpetual succession and be capable of suing and being sued. ZMDA may be capable of holding, purchasing, acquiring and in any legal way disposing, letting or renting any movable or immovable property’s shall have its common seal, which shall be in such shape, size and form as the General Assembly may determine. This common seal shall be appended to all official ZMDA documents. The Head office shall be in the Municipality of Zanzibar Town and ZMDA may open sub-offices in any part of Zanzibar. The ZMDA shall have the following bodies governing and directing the association such as General Assembly, Executive Committee and Board of Trustees. The executive committee shall compose of President, Vice President, Executive Secretary, Treasurer and 3 members elected by the General Assembly.</p>
                    <p>Zanzibar Medical and Dental Association (ZMDA) is an organ which assist medical professionals and the Government in order to improve health services and medical ethics in appropriate position. There shall be the following categories of members such as Ordinary member, Associate members and Honorary members. Membership shall be open to any individual or recognized Institution or Organization as provided for under Article 7 in the Constitution upon filling in application form and having it approved by the Executive Committee.</p>
                    <p>Generally, being a member of ZMDA, our vision is to create updated and standard quality and ethical health care services among medical professionals.</p>
                    <p>Being member of ZMDA would benefit from labor law advice, capacity building training, uniting medical practitioners in Zanzibar, to attend conference and workshop, easy access to evidence based information. Private practice support. </p>
                </div>
                <div className="col-lg-5" style={{"min-height": "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="user/images/IMG_3528.jpg" style={{"object-fit": "cover"}} />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

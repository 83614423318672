import React from 'react'

export const Member = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
                <h3 className="fw-bold text-success text-uppercase">Type of Member</h3>
                <h1 className="mb-0">ZMDA Type of Membership</h1>
            </div>
            <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                        <i className="fa fa-shield-alt text-white"></i>
                    </div>
                    <h4 className="mb-3 ">Ordinary Members:</h4>
                    <p className="m-0">Ordinary members are Medical, Dental and its allied professionals
                      registered by the Zanzibar Medical Councils. These include Assistant Medical 
                      Officers, Medical Officers, Assistant Dental Officers, Dentists, Medical Specialists, 
                      Clinical Officers and Dental therapists.</p>
                    <a className="btn btn-lg btn-primary rounded" href="">
                        <i className="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                        <i className="fa fa-chart-pie text-white"></i>
                    </div>
                    <h4 className="mb-3 ">Associate Members:</h4>
                    <p className="m-0">Associate members are Medical and Dental Practitioners registered by other Medical councils
                     recognized by Zanzibar Medical Council. These shall also include National or International organizations or 
                     agencies interested in supporting health programs in Zanzibar</p>
                    <a className="btn btn-lg btn-primary rounded" href="">
                        <i className="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                        <i className="fa fa-code text-white"></i>
                    </div>
                    <h4 className="mb-3 ">Honorary Members:</h4>
                    <p className="m-0">These are members nominated as such by the Executive Committee of ZMDA and approved by 
                    the General Assembly for their contribution to the welfare of the</p>
                    <a className="btn btn-lg btn-primary rounded" href="">
                        <i className="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b4.jpg"  alt="" />
     </td>
     </tr>
     
      
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>20/8/1959
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Mwembetanga </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 777 453 887</td>
     </tr> */}
     

   </table>
   </div>
      
    <h1>Dr. Mwanaheri Ahmed Abdulrahim Mahmoud</h1>
    <hr/>
    
    <h4><strong>EDUCATION</strong></h4>
      <ul>
        <li>Doctor of Medicine (MD):  University of Daresalaam, Tanzania 1978-1983</li>
        <li>Master of Public Health (MPH) (Major in Maternal &Child Health): San Diego State University, USA 1986-1987</li>
      </ul>
      <h4><strong>SHORT COURSES:</strong></h4>
      <ul>
        <li>Contraceptive Technology Update: Center for Family Studies (CAFS) Kenya, 1988</li>
        <li>Management of FP Programs: Center for Family Studies (CAFS) Kenya, 1989</li>
        <li>Bussiness Software Specialist (Word, Database, Excel) :Computer Ed Business Institute, MA. USA. 2000</li>
        <li>Care and Treatment of HIV cases: Zanzibar, Tanzania 2006</li>
        <li>Diagnostic Ultrasound: World Medical Colors from Italy Zanzibar, Tanzania 2007</li>
        <li>Basic Management: ESAMI, Arusha Oct 2008:</li>
        <li>Clinical Use of Blood Components: AABB and Haukeland in Zanzibar, Tanzania 2008, 2009 and 2010 respectively</li>
        <li>Research Ethics Review Training: Feb 2008; Jan 2009 and Oct 2009 SIDCER, Zanzibar </li>
        <li>Study Tour on Blood Banks: Haukeland University, Norway May to June 2010</li>
        <li>USG Grants Administration:  CDC, Daresalaam, Tanzania 2011</li>
        <li>Study Tour on Blood Banks: Nepal Redcross Blood Service, Nepal March 2015</li>
      </ul>

      <h4><strong>WORK EXPERIENCE</strong></h4>
      <h6>Managing Director WAJAMAMA Wellness Center Zanzibar, Dec 2019 to Date</h6>
      <p>Involved in the establishment of the WAJAMAMA Wellness Centre. Managing and supervising the primary care activities of the Family and Women’s Health Care Centre and ensure delivery of quality holistic care in line with the WAJAMAMA mission and vision. Ensured proper human resource management and collaboration with relevant partners</p>

      <h6>Program Manager  Zanzibar National Blood Transfusion Services Zanzibar,July 2007 to 2017</h6>
      <p>Managing the Zanzibar National Blood Transfusion Service under the Ministry of Health Zanzibar whereby my job as a Manager is planning and ensuring implementation of activities of collecting blood from Voluntary blood donors, and distributing safe blood which is free from Infections and of high quality standards to all the hospitals in Zanzibar. My job was also to monitor the activities and proper utilization of the distributed blood, supervision of the blood banks, mobilization of resources for the program and staff development, coordination with different Stake holders and Advocacy.</p>

      <h6>SOS Village Doctor (Part time) Hermann Gmeiner Medical Center SOS Children’s Village, Zanzibar, 1992 to June 2007</h6>
      <p>Provided medical care to the village children, all staff, their families and the school children. I participated in the management of the clinic and actively contributed to the expansion of the Medical Center service to cater for the neighboring community. I volunteered in Data collection of disease conditions and their analysis to be used by the Medical Center in planning for resource availability and controlling communicable diseases. I also volunteered in conducting annual medical examinations to all village children and providing health education and prevention from HIV to the school children.</p>

      <h6>Medical Practitioner/Incharge Pediatric Department Mnazimoja Hospital, Zanzibar, 1992 to 1995</h6>
      <p>As a Medical Practitioner my job was to take care of children who attended the outpatient and also those who were admitted in the wards. As an In charge, my role was also to manage the department and supervise the staff.</p>

      <h6>Registrar Obs/gyn Hindu Mandal Hospital Daresalaam, Tanzania, 1989 to 1991</h6>
      <p>I worked as a Medical Practitioner at the Obstetric and Gynecology Department whereby my job was to take care of women who attended for Antenatal Care, Delivery, and those who had gynecological problems.</p>

      <h6>Manager MCH/Family Planning Program Ministry of Health, Zanzibar, 1987 to 1989</h6>
      <p>Managing the Maternal and Child health and Family Planning Program of the Ministry of Health. My job was planning and ensuring implementation of activities, monitoring and supervision of all the Health facilities engaged in Maternal and child health and family planning clinics. I also conducted MCH and Family Planning Training to the MCH Aides.</p>

      <h6>Medical Practitioner Ob/Gyn Department Mnazimoja Hospital, 1984 to 1986</h6>
      <p>Worked as Medical Practitioner at the Ob/Gyn department where I took care of antenatal women, deliveries and women who presented with gynecological problems. I performed minor gynecological procedures and assisted in major procedures. During the period, I also performed clinics to attend infertile couples and those interested in family planning.</p>

      <h4><strong>PARTIME WORK</strong></h4>
      <ul>
        <li>Zanzibar Medical Group Hospital General Practitioner 1995 to 2008</li>
        <li>Bank of Tanzania clinic, Zanzibar  General Practitioner 1995 to 1999</li>
      </ul>

      <h4><strong>VOLUNTARY AND COMMUNITY SERVICE:</strong></h4>
      <ul>
        <li>Teaching at School of Nursing in Obs/Gyn and Child development Ministry of Health, Zanzibar (1984 to 1994)</li>
        <li>Voluntary work 2 months at Obs/gyn and Pediatrics Almaaruf Hospital, Comoro Islands (1985)</li>
        <li>Teaching MCH aids on hormonal contraceptives technology MCH Program, Ministry of Health, Zanzibar (1988 to 1999)</li>
        <li>Assisted in Project proposal for extension of Zanzibar Family Planning project On behalf of Ministry of Health Zanzibar (1988)</li>
        <li>Representative Ministry of Health (MOH), in MOH-NSL (Nederland Stichting voor Leprabestrinjding) Midterm review of TB and Leprosy Control Program (Feb 1992)</li>
        <li>Representative Ministry of Health in MOH-DANIDA Primary Health Care Support Program review. (June to July 1992)</li>
        <li>Health education to SOS Children’s village community (1992 to 2000)</li>
        <li>Health education to SOS Hermann Gmeiner School on nutrition, Common diseases, HIV/Aids and their prevention (1992 to 2003)</li>
        <li>Volunteer work during summer at “National Center of Excellence For Women Health”. Boston Medical Center. MA. USA (2004)</li>
      </ul>

      <h4><strong>MEMBERSHIP AND COMMITTEES:</strong></h4>
         <ul>
            <li>Blood Transfusion Technical Committee, MnaziMmoja Hospital, Zanzibar (1992)</li>
            <li>Malaria Control Technical Committee Ministry of health, Zanzibar (1992)</li>
            <li>Zanzibar Health Research Council (1992 to 1993)</li>
            <li>JNSP (Joint Nutrition Support Program) by UNICEF, Technical committee, Ministry of Health, Zanzibar (1992)</li>
            <li>Board Member, SOS Children’s Village, Zanzibar (1997 to 2000)</li>
            <li>Safe Motherhood Technical Committee, MOH, Zanzibar (1998 to 1998)</li>
            <li>Data Safety and Monitoring Committee, Public health Laboratory, Zanzibar (2003)</li>
            <li>Founder member, CIDAC (The Cancer Information, Diagnostics and Advise Center) an NGO (2003)</li>
            <li>Member of Public Health Association of Tanzania  (2003)</li>
            <li>Member, Zanzibar Medical and Dental Association (2008 to date)</li>
            <li>MemberofZanzibarMedicalResearchEthicsCommittee (Oct 08 to 2011)</li>
            <li>Secretary, Zanzibar Blood Transfusion Committee (2009 to 2017)</li>
            <li>Member, Mnazimoja Hospital Technical Committee (2020 to 2022)</li>
            <li>Board Chairman, WAJAMAMA Foundation, Zanzibar (June 2021 to Feb 2024)</li>
         </ul>

      <h4><strong>RESEARCH WORK:</strong></h4>
        <ul>
          <li>Principal Investigator: “Blood Phenotypes amongst blood donors in Zanzibar (2012)</li>
          <li>Co Investigator “Description of current clinical patterns of blood use, quantification of national blood demand, and estimation of national blood needs in Tanzania (2013)</li>
        </ul>
    </div>  
    </div>
  )
}







 






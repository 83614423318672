import React, {useState,useEffect} from 'react'
// import {NavLink} from "react-router-dom";

export const Nav = () => {
    const [activeLink, setActiveLink] = useState('/');

    useEffect(() => {
        const currentPath = window.location.pathname;
        setActiveLink(currentPath);
    }, []);

    const handleNavItemClick = (link) => {
        setActiveLink(link);
    };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <a href="/" className="navbar-brand p-0">
                <h1 className="m-0"><img src="user/img/zmda.png" width="100" height="auto" alt="logo" /></h1>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                    <a href="/" className={`nav-item nav-link ${activeLink === '/' ? 'active' : ''}`} onClick={() => handleNavItemClick('/')}>Home</a>
                    <a href="/about" className={`nav-item nav-link ${activeLink === '/about' ? 'active' : ''}`} onClick={() => handleNavItemClick('/about')}>About</a>
                    <a href="/leader" className={`nav-item nav-link ${activeLink === '/leader' ? 'active' : ''}`} onClick={() => handleNavItemClick('/leader')}>Leader</a>
                    <a href="/member" className={`nav-item nav-link ${activeLink === '/member' ? 'active' : ''}`} onClick={() => handleNavItemClick('/member')}>Members</a>
                    <a href="/news" className={`nav-item nav-link ${activeLink === '/news' ? 'active' : ''}`} onClick={() => handleNavItemClick('/news')}>News</a>
                    <a href="/contact" className={`nav-item nav-link ${activeLink === '/contact' ? 'active' : ''}`} onClick={() => handleNavItemClick('/contact')}>Contact</a>
                </div>
                <a href="/registration" className="btn btn-success py-2 px-4 ms-3">Registration</a>
            </div>
        </nav>
  )
}

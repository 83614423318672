import React from 'react'

export const Board = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
            {/* <h5 className="fw-bold text-primary text-uppercase">ZMDA Members</h5> */}
            <h1 className="mb-0">The Previous Board Members</h1>
        </div>
        <div className="row g-5">
           
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b3.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <a href='/mwatima-abdallah-juma'>
                    <div className="text-center py-4">
                        <h4 className="text-success">Dr. Mwatima Abdallah Juma</h4>
                        <p className="text-uppercase m-0">ZMDA Board Members</p>
                    </div>
                    </a>
                </div>
            </div>

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b6.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <a href='hassan-said-mzee'>
                    <div className="text-center py-4">
                        <h4 className="text-success">Mr Hassan Said Mzee</h4>
                        <p className="text-uppercase m-0">ZMDA Board Members</p>
                    </div>
                    </a>
                </div>
            </div>

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b7.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <a href='/ahmed-maulid-haji'>
                        <h4 className="text-success">Mr Ahmed Maulid Haji</h4>
                        <p className="text-uppercase m-0">ZMDA Board Members</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b8.jpg" alt="" />
                        {/* <div className="team-social">
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <a href='/mohammed-ahmed-mohammed'>
                            <h4 className="text-success">Mr Mohammed Ahmed Mohammed</h4>
                            <p className="text-uppercase m-0">ZMDA Board Members</p>
                        </a>
                    </div>
                </div>
            </div>

            {/* <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src="user/images/b7.jpg" alt="" />
                        // <div className="team-social">
                        //     <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                        //     <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                        //     <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                        //     <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        // </div>
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-primary">Ahmed Maulid Haji</h4>
                        <p className="text-uppercase m-0">ZMDA Board Members</p>
                    </div>
                </div>
            </div> */}


        </div>
    </div>
</div>
  )
}

import React from 'react'

export const News = () => {
  return (
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{"max-width": "600px"}}>
            <h3 class="fw-bold text-success text-uppercase">Latest News</h3>
            <h1 class="mb-0">Read The Latest News</h1>
        </div>
        <div class="row g-5">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div class="blog-item bg-light rounded overflow-hidden">
                    <div class="blog-img position-relative overflow-hidden">
                        <img class="img-fluid" src="user/img/first.jpg" alt="" />
                        <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">First Meeting</a>
                    </div>
                    <div class="p-4">
                        <div class="d-flex mb-3">
                            <small><i class="far fa-calendar-alt text-primary me-2"></i>30 Dec, 2023</small>
                        </div>
                        <h4 class="mb-3">Transitional Leadership Selection</h4>
                        <a class="text-uppercase" href="/detail">Read More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div class="blog-item bg-light rounded overflow-hidden">
                    <div class="blog-img position-relative overflow-hidden">
                        <img class="img-fluid" src="user/img/third.jpg" alt="" />
                        <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Committee Meeting</a>
                    </div>
                    <div class="p-4">
                        <div class="d-flex mb-3">
                            <small><i class="far fa-calendar-alt text-primary me-2"></i>21 Jan, 2024</small>
                        </div>
                        <h4 class="mb-3">Meeting with the Previous Leadership</h4>
                        <a class="text-uppercase" href="/meeting-with-the-previous-leadership">Read More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div class="blog-item bg-light rounded overflow-hidden">
                    <div class="blog-img position-relative overflow-hidden">
                        <img class="img-fluid" src="user/img/secornd.jpg" alt="" />
                        <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Zoom Meeting</a>
                    </div>
                    <div class="p-4">
                        <div class="d-flex mb-3">
                            <small><i class="far fa-calendar-alt text-primary me-2"></i>24 Feb, 2024</small>
                        </div>
                        <h4 class="mb-3 mt-3">Introduction of ZMDA </h4>
                        <a class="text-uppercase" href="/intro-zmda">Read More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

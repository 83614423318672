import React from 'react'

export const Condtion = () => {
  return (
    <div className="col-lg-4">
    <div className="section-title position-relative pb-3 mb-5">
        <h5 className="fw-bold text-success text-uppercase">Registration Form</h5>
        <h1 className="mb-0">ZANZIBAR MEDICAL AND DENTAL ASSOSIATION (ZMDA) ZANZIBAR </h1>
    </div>
    <div className="row gx-3">
        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
            <h5 className="mb-4"><i className="fa fa-reply text-success me-3"></i>Reply within 24 hours</h5>
        </div>
        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
            <h5 className="mb-4"><i className="fa fa-phone-alt text-success me-3"></i>24 hrs telephone support</h5>
        </div>
    </div>
    {/* <p className="mb-4">Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit. Sea dolore sanctus sed et. Takimata takimata sanctus sed.</p> */}
    <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
        <div className="bg-success d-flex align-items-center justify-content-center rounded" style={{"width": "60px", "height": "60px"}}>
            <i className="fa fa-phone-alt text-white"></i>
        </div>
        <div className="ps-4">
            <h5 className="mb-2">Call to ask any question</h5>
            <h4 className="text-success mb-0">+255 778 009 859</h4>
        </div>
    </div>
</div>
  )
}

import React from 'react'

export const About = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h3 className="fw-bold text-uppercase title-header">About Us</h3>
                        <h1 className="mb-0">ZANZIBAR MEDICAL AND DENTAL ASSOSIATION (ZMDA) ZANZIBAR </h1>
                    </div>
                    <p className="mb-4">
                     Medical and dental association  is an organization formed by healthcare professionals,
                     including doctors and dentists, to promote collaboration, professional development, and 
                     the interests of their members. This association often engage in activities such as organizing 
                     conferences, providing educational resources, advocating for healthcare policies, and facilitating 
                     networking opportunities amoung members.
                    </p>
                    <a href="/about" className="btn btn-success py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Red More</a>
                </div>
                <div className="col-lg-5" style={{"min-height": "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="user/images/IMG_3528.jpg" style={{"object-fit": "cover"}} />
                    </div>
                </div>
            </div>
        </div>
    </div>
   )
}

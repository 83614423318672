import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b6.jpg"  alt="" />
     </td>
     </tr>
      
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>20/8/1959
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Kikwajuni </td>
     </tr>
     
     {/* <tr>
       <th>Years active</th>
       <td>2001-Present</td>
     </tr>
     
     <tr>
       <th>Phone Number</th>
       <td>+255 741 610 590</td>
     </tr> */}
     

   </table>
   </div>
      
    <h1> Dr. Juma Salum Mbwana (Mambi)</h1>
    <hr/>
    
    <h4><strong>EDUCATION</strong></h4>
      <ul>
        <li>PRIMARY EDUCATION - 1948 TO 1957 Z'BAR PRE-SECONDARY EDUCATION 1958 TO 1960 CAIRO EGYPT,</li>
        <li>RUSSIAN LANGUAGE 1960 TO 1962 GEORGI RUSSIA</li>
        <li>UNIVERSITY EDUCATION SOVIET UNION. KIEV STATE UNIVERSITY 1962 Ti 1966</li>
      </ul>

      <h4><strong>QUALIFICATION</strong></h4>
      <ul>
        <li>M.A. INTERNATIONAL LAW OPTION</li>
      </ul>

      <h4><strong>COURSES ATTENDED</strong></h4>
      <ul>
        <li>ARABIC LANGUAGE - CAIRO - EGYPT RUSSIAN LANGUAGE - SOVIET UNION WORKERS PARTICIPATION - DAR ES SALAAM</li>
      </ul>

      <h4><strong>POSITION HELD ZANZIBAR</strong></h4>
      <ul>
        <li> EDUCATION OFFICER GAMAL ABDUL NASSER SEC. SCHOOL AND ZANZIBAR SCHOOL O ECONOMICS 1967 TO 1968</li>
          <li>  REPORTER - THE THEN STANDAR NEWSPAPERS / DAILY AND SUNDAY NEWS 1969 - 1974 ZANZIBAR.</li>
          <li>  FORMER SECRETARY GENERAL ZANZIBAR CHAMBER OF COMMERC INDUSTRY AND</li>
          <li>DIRECTOR COMMERCIAL AN INDUSTRIAL CONSULTANTS COMPAN LTD 1987 - 2000</li>
          <li>FORMER BOARD MEMBER ZANZIBA GORVENMENT NEWSPAPER - NURU</li>
          <li> FORMER DIRECTOR ZANZIBAR LEGA  SERVICE CENTRE 1992 - 2000</li>
      </ul>
      
      <h4><strong>UNION GOVERNMENT APPOINTMENTS:</strong></h4>
      <ul>
        <li> MANPOWER MANAGEMENT OFFICEF MINISTRY OF NATURAL RESOURCE AND TOURISM -1975-1979</li>
        <li>PUBLIC RELATION OFFICER TANZANI ELECTRIC SUPPLY COMPAN (TANESCO) 1979 - 1984 (RESIGNED)</li>
      </ul>

      <h4><strong>PRESENT POSITION</strong></h4>
      <ul>
        <li> ADVOCATE, THE HIGH COURT OF ZANZIBAR</li>
        <li>COMPANY SECRETARY, MAHA TRAVEL ANI TOURS SAFARI.</li>
        <li>CHAIRMAN, BOARD OF TRUSTEES SO CHILDREN'S VILLAGE ASSOCIATION ZANZIBAR</li>
        <li>DIRECTOR, NGO RESOURCE CENTRE ZANZIBAR.</li>
      </ul>

      <h4><strong>PRESIDENTIAL APPOINTMENT: ZANZIBAR GOVERNMENT:</strong></h4>
      <ul>
        <li> COMMISSIONER AND VICE CHAIRMAN THIS ZANZIBAR ELECTORAL COMMISSION 1993. 1998 AND 1998 - 2002</li>
        <li>MEMBER OF THE COMMITTEE FOR THI ESTABLISHMENT OF NURU NEWSPAPER It ZANZIBAR 1992</li>
        <li>MEMBER OF THE LAW REVIEW COMMISSION ZANZIBAR 1998 - 1999.</li>
      </ul>

      <h4><strong>UNION PRESIDENTIAL APPOINTMENTS: DAR ES SALAAM</strong></h4>
      <ul>
        <li>MEMBER OF THE COMMISSION OF EXPERT ON PRESIDENTIAL ELECTIONS MULTIPARTY. 1992 TO 1993.</li>
        <li>MEMBER OF THE WHITE PAPER COMMISSIO FOR THE CHANGES OF THE UNIO CONSTITUTION 1998 - 1999</li>
      </ul>
 
      <h4><strong>LANGUAGES</strong></h4>
        <ul>
          <li>KISWAHILI</li>
          <li>ENGLISH</li>
          <li>RUSSIAN</li>
          <li>ARABIC</li>
        </ul>

    </div>  
    </div>
  )
}
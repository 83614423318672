// import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './component/Home/Home';
// import { Header } from './menu/Header';
// import { Nav } from './menu/Nav';
// import { Footer } from './menu/Footer';
import { About } from './component/About/About';
import { Leader } from './component/Leader/Leader';
import { News } from './component/News/News';
import { Contact } from './component/Contact/Contact';
import { Registration } from './component/Registraton/Registration';
import { Member } from './component/Member/Member';
import { Details } from './component/Detail/Details';
import { Biography } from './component/Biography/Biography';
import { Details1 } from './component/Detail1/Details';
import { Details2 } from './component/Detail2/Details';
import { DrMambiDoc } from './component/Bio/Founder/DrMambi/DrMambi';
import { Mwatima } from './component/Bio/Board/DrMwatima/Mwatima';
import { MrHassan } from './component/Bio/Board/MrHassan/MrHassan';
import { Azza } from './component/Bio/Founder/Azza/Azza';
import { MrAhemd } from './component/Bio/Board/MrAhmed/MrAhmed';
import { Mwanaheri } from './component/Bio/Founder/Mwanaheri/Mwanaheri';
import { DrAhmed } from './component/Bio/Founder/Ahmed/DrAhmed';
import { DrSaid } from './component/Bio/Founder/Said/DrSaid';
import { MrMohammed } from './component/Bio/Board/MrMohammed/MrMohammed';
import { Dashboar } from './component/Adim/Dashboar';
import Layout from './menu/Layout';
import LoginPage from './component/Login';
import { AdminReg } from './component/Adim/AdminReg';
import { AdminAddReg } from './component/Adim/AdminAddReg';

function App() {
  return (
    <div className="App">
       <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="leader" element={<Leader />} />
        <Route path="news" element={<News />} />
        <Route path="contact" element={<Contact />} />
        <Route path="registration" element={<Registration />} />
        <Route path="member" element={<Member />} />
        <Route path="detail" element={<Details />} />
        <Route path="meeting-with-the-previous-leadership" element={<Details1 />} />
        <Route path="intro-zmda" element={<Details2 />} />
        <Route path="biography" element={<Biography />} />
        <Route path="Juma-salum-mbwana" element={<DrMambiDoc />} />
        <Route path="mwatima-abdallah-juma" element={<Mwatima />} />
        <Route path="hassan-said-mzee" element={<MrHassan />} />
        <Route path="azza-amir-said" element={<Azza />} />
        <Route path="ahmed-maulid-haji" element={<MrAhemd />} />
        <Route path="mwanaheri-ahmed-abdulrahim-mahmoud" element={<Mwanaheri />} />
        <Route path="ahmed-mohammed-khatib" element={<DrAhmed />} />
        <Route path="said-ali-said" element={<DrSaid />} />
        <Route path="mohammed-ahmed-mohammed" element={<MrMohammed />} />
      </Route>
      <Route path="/dashboard" element={<Dashboar />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/registed" element={<AdminReg />} />
      <Route path="/newmember" element={<AdminAddReg />} />
    </Routes>
    </div>
  );
}

export default App;

import React from 'react'

export const Footer = () => {
  return (
    <div>
        <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-4 col-md-6 footer-about bg-white text-center">
                      <h1 className="m-0"><img src="user/img/zmda.png" width="350" height="auto"  /></h1>
                </div>
                <div className="col-lg-8 col-md-6">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-12 pt-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Contact</h3>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-geo-alt text-primary me-2"></i>
                                <p className="mb-0">71103 Street, Mitondooni - Zanzibar</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-envelope-open text-primary me-2"></i>
                                <p className="mb-0">info@zmda.or.tz</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-telephone text-primary me-2"></i>
                                <p className="mb-0">+255 778 909 090</p>
                            </div>
                            <div className="d-flex mt-4">
                                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Quick Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <a href="/" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                <a href="/about" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                <a href="/leader" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>Leader</a>
                                <a href="/member" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>Members</a>
                                <a href="/news" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>News</a>
                                <a href="/contact" className="text-light"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Useful Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Staff Mail</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid text-white" style={{"background": "#061429"}}>
        <div className="container text-center">
            <div className="row justify-content-end">
                <div className="col-lg-8 col-md-6">
                    <div className="d-flex align-items-center justify-content-center" style={{"height": "75px"}}>
                        <p className="mb-0">&copy; <a className="text-white border-bottom" href="#">ZMDA</a>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

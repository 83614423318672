import React from 'react'
import { History } from './History'
import { Founder } from './Founder'
import { Nav } from '../../menu/Nav'
import { TopMenu } from './TopMenu'

export const About = () => {
  return (
    <div>
       <div className="container-fluid position-relative p-0">
        <Nav />
        <TopMenu />
       </div>
        <History />
        <Founder />
    </div>
  )
}

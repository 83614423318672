import React from 'react'
import './Bio.css';
export const Detail = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container">

   <div class="container2">
   <table class="infobox biograph vcard" style={{"width":"20em"}}>
  
     <tr>
       <td colspan="2" class="text-center"><img src="user/images/b8.jpg"  alt="" />
      
     </td>
     </tr>
     
     {/* <tr>
       <th><strong>Date of  Birth: </strong></th>
       <td>2/05/1940
       </td>
     </tr> */}
     
     <tr>
       <th>Residence</th>
       <td>Tanzania
       </td>
     </tr>
     
     <tr>
       <th>Marital status: </th>
       <td>Married</td>
     </tr>
     
     <tr>
       <th>Address</th>
       <td> Zanzibar - Mwembetanga </td>
     </tr>

   </table>
   </div>
      
    <h1> Mr Mohammed Ahmed Mohammed</h1>
    <hr/>
    
    <h4><strong>Educational background</strong></h4>
    <ul>
      <li>Master in Political Science –Holland</li>
      <li>Bachelar of Art in Education- University of Dar-es-salaam</li>
      <li>Teacher Training College –Nkrumah Zanzibar</li>
      <li>Secondary Education – Ben Bella than  Completed  at  Lumumba</li>
      <li>Primary School;Kiembe Samaki ,Zanzibar</li>
    </ul>
      
    <h4><strong>Working experience</strong></h4>
      <ul>
        <li>Principal Secretary First  Vice President Office 1983-1984(Mhe. Alhaji  Aboud Jumbe Mwinyi )</li>
        <li>Principal Secretary First Vice President  Office 1984-1985 (Mhe.Alhaji  Ali Hassan Mwinyi )</li>
        <li>Principal Secretary  Second Vice President  Office 1985-1990 (Mhe. Alhaji Idrisa Abdulwakil )</li>
        <li>Principal  Secretary  Second Vice President Office 1990-1995 ( Mhe.Alhaji Dr  Salmin  Amour Juma )</li>
        <li>Director  Ministry of Culture, Art and Sports  Zanzibar.</li>
        <li>Executive Secretary Culture Musical Club – 1983</li>
        <li>Teaching  at Secondary School  Shangani</li>
      </ul>
      
      <h4><strong>Languages</strong></h4>
      <ul>
        <li>Speaking fluently Swahili</li>
        <li>Speaking fluently English</li>
      </ul>

      <h4><strong>Hobbies</strong></h4>
      <ul>
        <li>Musician</li>
        <li>Poem composition,singer</li>
      </ul>
      
      
    </div>  
    </div>
  )
}
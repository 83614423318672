import React from 'react'
import { Nav } from '../../menu/Nav'
import { Category } from './Category'
import { Slider } from './Slider'

export const Member = () => {
  return (
    <div>
       <div className="container-fluid position-relative p-0">
        <Nav />
         <Slider />
       </div>
        <Category />
    </div>
  )
}
